$content-width:    1200px;
@import "minima";

.wrapper .home {
  max-width: 800px;
  display: block;
  margin: 0.5em auto;
}

img[src*="#center"] {
  display: block;
  margin: 0.5em auto;
}

.site-header .site-title {
  font-size: 32px;
  font-style: italic;
}